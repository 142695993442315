export const  Data =[
    { key: 'Name',
      value:'we will study ur idea and propose a business name ,we will find a white space and create a name as unique as  ur idea '

    },
    { key: 'Identity',
      value:'we will create a starter identity package including logo, business cards, flyers, posters and social media-template. '

    },
    { key: 'Playbook',
      value:'We will develop a strategy playbook to launch and amplify your brand. '

    }
    ,
    { key: 'Website',
    value:'  we  will design and develop a dope  website that will delight  ur target audience  and meet ur marketing needs.'

  }
  
]