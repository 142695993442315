import React from 'react';
import Brand from './componets/Brand';
import Brandstart from './componets/Brandstart';
import Contact from './componets/Contact';
import Everything from './componets/Everything';
import Grow from './componets/Grow';
import Footer from './componets/Footer';
import Intro from './componets/Intro';
import Navbar from './componets/Navbar';
import Skills from './componets/Skills';
import Dreamit from './componets/Dreamit';
import Me from './componets/Me';
import Backbone from './componets/Backbone';
import Tools from './componets/Tools';
import Faq from './componets/Faq';

function App() {
  return (
    <div>
      
      <Navbar />
      <Brand />
      <Brandstart />
      <Everything />
      <Grow />
      <Dreamit />
      <Backbone />
      <Contact />
      <Me />
      <Faq />
      <Footer />
     
      

    </div>
  )
}

export default App;
