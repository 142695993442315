import React from 'react';
import {FaWhatsappSquare,FaPhoneAlt}from "react-icons/fa";
import Upwork from '../images/upwork.png';
import Qrcode from '../images/qrcode.png';

function Contact() {
  return (
    <div className='w-full h-full bg-[#410988] pt-16 pb-16' id="contacts">
      <section className='mx-auto  px-2 md:p'>
        <div className='bg-white rounded-[25px] ç   '>
          <h1 className='text-[#4624CA] text-5xl font-bold text-center px-6 pt-16 md:pt-20 md:px-20'>Ready to get started? Chat with me  over zoom</h1>
          <p className='text-2xl font-medium text-[] text-center px-4 py-6 md:py-12 md:px-[300px] '>There’s no perfect time to get started. Whether you have a formal proposal or a few napkin sketches, we’re always happy to chat about your project at any stage of the process.
</p>
           <h1 className='font-bold text-center text-4xl  text-transparent bg-clip-text bg-gradient-to-r from-[#410988] via-[#D50EF5] to-[#0C15F0] md:text-7xl '>CONTACT ME</h1>
      
       <div className='flex justify-center flex-col md:flex-row pt-4'>
        <div className=' flex items-center justify-center  my-4 gap-4 md:p-20'>
                  <a href='tel:=256708699881' className=' md:w-auto px-3 md:px-8 py-5 border bg-white border-slate-400 shadow-md shadow-slate-400 rounded-md hover:border-lime-900
                   duration-100 ease-in-out cursor-pointer flex items-center justify-center gap-3 '>
                     <FaPhoneAlt className='text-[#4624CA] text-[60px] cursor-poniter '/>
                     <p className='text-lg text-slate-700 p-4'>Call Me</p>
                   </a>
                </div>
          <div className=' flex items-center flex-wrap justify-center  my-4 gap-4 md:p-20'>
                  <a href='https://wa.me/256708699881' className=' md:w-auto px-3 md:px-8 py-5 border bg-white border-slate-400 shadow-md shadow-slate-400 rounded-md hover:border-lime-900
                   duration-100 ease-in-out cursor-pointer flex items-center justify-center gap-3'>
                     <FaWhatsappSquare className='text-[#4624CA] text-[60px] cursor-poniter '/>
                     <p className='text-lg text-slate-700 p-2'>WhatsApp</p>
                   </a>
                </div>
        </div>
        
        <h2 className='text-center text-[#4624CA] font-bold text-3xl py-4'>Find Me on Upwork</h2>
        <p className='text-center text-black text-[20px] font-light md:text-2xl md:font-medium  '> Hire me via Upwork Platform</p>
        <div className='flex  flex-col justify-center items-center pb-16'>
        <img className=' justify-center py-12' src={Upwork} alt=''/>
        <a className=' justify-center text-[25px] font-light text-[#4624CA] text-center border-2 border-[#4624CA] p-4 cursor-pointer hover:bg-[#4624CA] hover:text-white ' 
         href='https://www.upwork.com/freelancers/~01085fa0d21db03981' alt=''>Continue To Upwork</a>
        <h1 className='text-[#4624CA] text-3xl font-bold text-center px-2 py-8'>Share With a Friend</h1>
        <img className=' justify-center pt-4 px-6 md:px-[500px]' src={Qrcode} alt=''/>
        <p className='border-2 border-[#4624CA] text-[15px] font-light text-[#4624CA] p-3 '>Scan ME 👆</p>
        </div>
        
        </div>
      </section>
    </div>
  )
}
export default Contact