import React from 'react'
import Growbusi from '../images/growbusi.png';
import Growbusi2 from '../images/growbusi2.png';
import Socialp from '../images/socialp.png'
import Web3 from '../images/web3.png'
function Grow() {
  return (
    <div className='w-full  h-full'>
        <section className='container mx-auto'>
            {/* grow ur business images*/}
            <div className='  flex flex-col justify-center items-center'>
                <div className=' py-10 md:py-20'>
                    <img src={Growbusi} alt="" className='w-full h-full'/>

                </div>
                <div className='md:pt-6'>
                <img src={Growbusi2} alt="" className=' w-[100px] md:w-[200px]'/>

                </div>

            </div>
            {/* grow ur business online text*/}

            <div className='text-center flex flex-col'>
                <h2 className='font-bold  px-8 md:px-20 text-4xl md:text-[95px] md:leading-[95px] pt-12'>Grow Your Business Online</h2>
                <h3 className='text-2xl text-[#E18E8E] md:text-5xl px-8 py-8 md:py-16'>
                      The Future Is Here And Its "Online"
                </h3>
                <p className=' font-light text-[20px] text-black md:text-[25px] px-6 md:px-[200px] '>
                lets build and grow your business online with a combination of strategies from 
social-media , webistes to native mobile apps that will help u to sacle to hundreds and thousands of users to catapult growth to greatness.
                </p>

            </div>
      <div className='px-4 pt-16 '>
        <div className='  w-full h-full flex flex-col text-center items-center justify-center  shadow-lg shadow-slate-500 rounded-[25px] bg-[#46A9ED] md:flex-row'>
         <div className='md:w-1/2  flex'>
         <img className=' w-full h-[300px] md:h-[500px] rounded-t-[25px] md:rounded-l-[25px] md:rounded-t-none md:rounded-tl-[25px]' src={Socialp} alt=''/>
         </div>
         <div className=' flex-col text-center md:w-1/2 md:h-full '>
         <h2 className='text-[#3F3D56] font-bold text-3xl md:text-[50px] mt-4 px-6 md:py-6 md:leading-[50px]'>Elevate your social-media presence</h2>
         <p className='text-black text-[20px] px-8 py-2 pb-8 font-light md:px-[130px]'>our social-media marketing template will help you tell  unique great stories across
          all popular social platforms that make you standout from the crowd,create  leads,retain customers and grow your business</p>
        </div>

        </div>
        </div>
      {/* create a website*/}
        <div className='flex flex-col justify-center items-center w-full h-full  mt-12'>
            <h1 className='pt-10 font-extrabold text-6xl text-center text-[#3C3636]  md:text-8xl md:pt-16  '>Create a website</h1>
            <img className='px-2 w-full py-12 md:py-[70px]' src={Web3} alt='' />
            <h2 className=' text-center text-2xl text-[#3C3636] font-bold  py-4 md:py-[50px] px-12 md:px-[250px] md:text-5xl '>We design , develop and deploy powerful  webapps & mobile apps</h2>
            <p className='text-black text-[20px] font-light text-center px-12 md:px-[200px] md:pb-12 '>Your website or app connects the world to you and your product. We build effective sites and web apps
                 that bring your customers the right content, quickly.  Our human-centered aproach gives  you confidence 
                 in your message and tooling so you can trust that everyone who sees your product will enjoy it.

             </p>


        </div>

        </section>

    </div>
  )
}

export default Grow;