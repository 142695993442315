import React from 'react'
import Tailwind from '../images/tailwind.png'
import Node from '../images/node.png'
import Rreact from '../images/rreact.png'
import Mongodbb from "../images/mongodbb.png"
import { Link, animateScroll as scroll } from "react-scroll";

function Footer() {
  return (
    <div className='container mx-auto '>
      <div className='px-16 justify-center items-center pt-8 sm:px-[300px] sm:mx-[200px]'>
      <Link
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
             <p className='text-center text-2xl text-white border- rounded-md bg-black px-4 py-4 '>Back To Top</p>
              </Link>
     
      </div>
      
       <div className='flex items-center justify-center text-slate-700 py-2 pt-20 '>Powered By</div>
         <div className='flex flex-col items-center justify-center '>
         <ul className=' flex   gap-4'>
           <li>
           <img className='w-[40px] h-[40px]'src={Tailwind} alt=''/>
           </li>
           <li>
           <img className='w-[40px] h-[40px]'src={Rreact} alt=''/>
           </li>
           <li>
           <img className='w-[40px] h-[40px]'src={Node} alt=''/>
           </li>
           <li>
           <img className='w-[40px] h-[40px]'src={Mongodbb} alt=''/>
           </li>

         </ul>
         <p className='text-black pt-4 text-[10px]'>Designed with ❤️ by Lule Ahmed </p>
         <p className='text-black pt-2 pb-4 '>&copy;copyright2023 by Lule Ahmed.</p>
         </div>
         
         
       </div> 
    
  )
}

export default Footer
