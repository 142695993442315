import React from 'react'
import{useState ,useRef ,useEffect } from 'react'
import {FaTimes} from 'react-icons/fa';
import Frame1 from '../images/frame1.svg';
import {BiMenuAltRight }from 'react-icons/bi';
import { Link, animateScroll as scroll } from "react-scroll";
function Navbar() {
    const disref = useRef(null);
    const [ nav,setnav]= useState(false);
    const handleclick =(element)=> setnav(!nav);
    useEffect(()=>{
      const handler = (e)=>{
        if(!disref.current.contains(e.target)){
          setnav(false);
          
        }
     };
      document.addEventListener("mousedown",handler);
      return()=>{
        document.removeEventListener("mousedowm",handler);
      }
    })
  return (
    <div className=' fixed w-full h-[66px] flex justify-between items-center px-4 bg-white opacity-95  text-slate-600 mb-12 shadow-lg shadow-slate-300'>
        <div className=' max-w-[1240px] flex gap-15 justify-items-start  pl-4'>
        <Link
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
               <img className=' flex flex-start justify-items-start  w-[300px] h-[200px] pb-4 mr-[200px] sm:w-[300px] sm:h-[200px] sm:items-left sm:pb-4' src={Frame1} alt=""/> 
              </Link>
            
            
        </div>
      {/*main menu */}
            <ul className='hidden md:flex'>
            <li className='px-2 text-black text-[20px] hover:text-slate-400 cursor-pointer'>
            <Link
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
              Home 
              </Link></li>
            <li className='px-2 text-black text-[20px] hover:text-slate-400 cursor-pointer'>
            <Link
                activeClass="active"
                to="quick"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
            Quickstart
              </Link></li>
            <li className='px-2 text-black text-[20px] hover:text-slate-400 cursor-pointer'>
            <Link
                activeClass="active"
                to="bone"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
               Tech-stack
              </Link></li>
            <li className='px-2 text-black text-[20px] hover:text-slate-400 cursor-pointer'>
            <Link
                activeClass="active"
                to="faq"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
               Faq 
              </Link></li>
            <li className=' mx-2 p-2  rounded-lg text-white bg-[#410988] cursor-pointer hover:bg-blue-700 '>
            <Link
                activeClass="active"
                to="contacts"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
              Contacts
              </Link></li>
            </ul>
            {/*humberg */}
            <div onClick={handleclick} className='md:hidden z-10'>
             {!nav ? <BiMenuAltRight className=' text-black  text-[100px] 0 w-[50px] h-[50px]'/> : <FaTimes className='text-black w-[30px] h-[30px]' />}
            </div>


        {/*mobile menu */}
        <ul  ref={disref} className={!nav ? 'hidden':'absolute top-[0] right-0 w-[75%] h-[500px] bg-white flex flex-col justify-items-left px-2 pt-16'}>
            <li className='py-4 px-6 text-4xl text-left text-slate-800 border-b-2 border-slate-400'>
            <Link
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
              <a href="#Brand" alt="">Home</a>
              </Link></li>
            <li className='py-4 px-6 text-4xl text-left text-slate-800 border-b-2 border-slate-400'>
            <Link
                activeClass="active"
                to="quick"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
              Quick-Start
              </Link></li>
            <li className='py-4 px-6 text-4xl text-left text-slate-800 border-b-2 border-slate-400'>
            <Link
                activeClass="active"
                to="bone"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
               Tech-stack
              </Link></li>

            <li className='py-4 px-6 text-4xl text-left text-slate-800 border-b-2 border-slate-400'>
            <Link
                activeClass="active"
                to="faq"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
               Faq 
              </Link></li>
            <li className='py-4 px-6 text-4xl text-left text-slate-800 border-b-2 border-slate-400'>
            <Link
                activeClass="active"
                to="contacts"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
               Contacts
              </Link></li>  
        </ul>
    </div>
  )
}

export default Navbar;