import React from 'react';
import Brand1 from '../images/brand1.png'
import Iphonembc from '../images/iphonembc.png'

function Brand() {


  return (
    <section className='container pt-[35px] mx-auto' id="home">
        <div className='mx-2 px-4  items-center justify-center'> 
            <div className='grid grid-cols-1 gap-20'>
                <div className='text-center my-4 md:my-10'>
                    <h1 className='  mt-10 text-center text-[45px] text-transparent bg-clip-text bg-gradient-to-r from-indigo-700 via-purple-500 to-pink-700 leading-none md:text-[100px] font-bold py-2'>
                        Create a brand & sell anything
                    </h1>
                    <p className='text-slate-700 mt-5 md:pt-8  md:px-[150px] md:mx-20  text-[20px]  md:text-[30px] font-medium font-poppins '>
                   Spin-up your own Brand and practically sell anything, whether you're just starting out or a mature business we gonna get u covered.
                    </p>
                    <img src={Brand1} alt='' className=' hidden pr-[180px]  md:block  md:w-full md:justify-center md:items-center md:pb-[40px] '/>
                    <img src={Iphonembc} alt='' className=' w-full mt-10 justify-center items-center  md:hidden '/>
                </div>

            

                

            </div>
        </div>
    </section>
  )
}

export default Brand;