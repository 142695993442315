import React from'react';
import {useState}from 'react';
import {Data} from '../data/Data';
import Butterschemacrop from '../images/butterschemacrop.png';
import Quickstart from '../images/quickstart.png';
const Brandstart =()=>{
const [Toggle,setToggle]=useState(false);
const handleClick = (a)=>{
    if (Toggle === a){
        return setToggle(false)
    }
    setToggle(a)
}
    return(
        <div className='container mx-auto mb-8  md:mt-[50px] md:mb-[200px]'>
            <section className=''>
            <div className='   mx-4 px-4   items-center justify-center'>
            
               
                
                <div className='flex flex-col md:flex-row w-full h-full pt-8  md:mx-10 '>
                    <div className='md:w-1/2 h-full flex-col'>
                    <h2 className='font-medium text-[30px] pb-4 text-[#000000] md:text-[45px] lg:text-[70px] lg:pb-6 leading-none'>Lets start now <br/>with quick start</h2>
                     
                
              {Data.map((items,a)=>(
                

                    
                        
                        <div onClick={()=> handleClick(a) }
                         className='w-full text-[25px] text-slate-800 inline-block '>{Toggle === a ? '-':'+'}
                            <div className='text-[25px] text-slate-800 inline-block px-4  '>
                                <h2>{items.key}</h2>
                            </div>
                            <div className={Toggle === a ?' w-full text-[20px] text-slate-500 px-8   md:w-[500px] h-full font-medium font-bodoni':'hidden'}>
                                <p>{items.value}</p>
                            </div>
                        </div>
                   
                 // {/**push */}
                    ))}
                    </div>
                    <div className='pt-8 md:pt-0 md:w-1/2 h-full flex-col  justify-center items-center'>
                        <img src={Butterschemacrop}alt='' className='  w-full h-full ' />

                    </div>
                    </div>
                   </div> 
                
                   
                   
       

                          
            
            </section>

    
        </div>

    )
}
export default Brandstart;