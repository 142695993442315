import React from 'react';
import Mepic from '../images/mepic.png';

function Me() {
  return (
    <div className='w-full h-full bg-[#F5F4E7] pb-20'>
        <section className='mx-auto container '>
            <div className='flex  md:justify-center md:items-center   '>
             <img className='flex md:items-center   md:justify-items-center pr-[180px] md:pr-0' src={Mepic} alt=''/>
            </div>
            <div className=' flex flex-col text-center md:pb-20'>
                <h2 className='text-left text-fuchsia-600 text-2xl md:text font-medium px-6 py-2 md:text-center md:px-[200px] '>Hello,<span className='text-zinc-900'> there I’am </span></h2>
                <h1 className='text-left text-5xl text-[#4624CA]  font-bold px-6  md:text-center md:px-[200px]'> Lule Ahmed </h1>

                <div className='text-left text-4xl text-zinc-900 font-medium px-6 pt-4 md:text-center md:px-[300px]'><span className='text-red-500'>Serial Entrepreneur,</span> </div>
                <div className='text-left text-4xl text-zinc-900 font-medium px-6 py-2 md:text-center md:px-[300px]'><span className='text-yellow-400'>UIUX designer,</span> <span className='text-green-600'>software developer</span><span className='text-red-600'> & </span>
 <span className='text-blue-600'>digital-marketer</span></div>
                <p className='text-left text-[15px] text-black font-meduim  px-6 py-4 md:text-center md:text-3xl md:px-[200px]'>Who's passionate about solving 
business problems with human-centered
design, Creating digital experiences by 
combining visual design with product thinking
to bring meaningful expriences alive.</p>

            </div>

        </section>
    </div>
  )
}

export default Me;