import React from 'react'

function Faq() {
  return (
    <div className='w-full h-full pt-20 'id="faq">
        <section className='container mx-auto'>
            <div className='  px-4 border-b border-black pb-12'>
                <h1 className='text-left text-4xl text-black font-bold p-4'>FAQ</h1>
                <div className=' flex flex-col border-t-2 px-6 border-black md:flex-row '>
                    <h2 className='md:w-1/2 text-left font-bold text-[15px] pt-4 md:px-12 md:py-20 '>1. How much  does  it cost to build a webite ?</h2>
                    <p className='md:w-1/2 py-4 md:px-12 md:py-20'>The super-basic website will cost u $99 , if u have ur own design and pretty much everything else sorted out. if not, note that  prices will increase to cover things like design, domain, ssl certificates and hosting</p>
                </div>
                <div className=' flex flex-col border-t-2 px-6 border-black md:flex-row '>
                    <h2 className='md:w-1/2 text-left font-bold text-[15px] pt-4 md:px-12 md:py-20 '>2. Can you help fix my SEO?</h2>
                    <p className='md:w-1/2 py-4 md:px-12 md:py-20'>I have experience in optimizing websites for search engines by improving the website code and structure, making it mobile-friendly, improving website content, and using proper linking strategies. These tactics can help improve your website's search engine ranking and attract more traffic. So, if you need help with your SEO, I would be happy to help you.</p>
                </div>
                <div className=' flex flex-col border-t-2 px-6 border-black md:flex-row '>
                    <h2 className='md:w-1/2 text-left font-bold text-[15px] pt-4 md:px-12 md:py-20 '>3. Hey do you cater for  international clients ?</h2>
                    <p className='md:w-1/2 py-4 md:px-12 md:py-20'>Yes, as a developer, I cater to clients from all around the world. With the help of technology, it's easy to work with clients from anywhere in the world. Whether you are located in the same city as me or on the other side of the globe, I am happy to work with you to create a Project that meets your needs and exceeds your expectations. Feel free to reach out to me, and let's discuss your website project in more detail.
I offer a range of communication options to make it easy for clients to get in touch with me. You can book a Zoom call with me or chat with me directly on WhatsApp or other messaging platforms, such as Slack or Skype. Whatever communication method you prefer, I am happy to accommodate it to make our collaboration as smooth and convenient as possible.</p>
                </div>
                <div className=' flex flex-col border-t-2 px-6 border-black md:flex-row '>
                    <h2 className='md:w-1/2 text-left font-bold text-[15px] pt-4 md:px-12 md:py-20 '>4. Can you guys manage my website for me ?</h2>
                    <p className='md:w-1/2 py-4 md:px-12 md:py-20'>Yes, as a website developer, I can certainly manage your website for you! I offer website maintenance and management services to ensure that your website stays up-to-date, secure, and running smoothly. Whether you need help with regular updates, security patches, or content management, I can provide you with a customized maintenance plan that meets your specific needs. Let me know if you would like to discuss more about my website management services..</p>
                </div>
                <div className=' flex flex-col border-t-2 px-6 border-black md:flex-row '>
                    <h2 className='md:w-1/2 text-left font-bold text-[15px] pt-4 md:px-12 md:py-20 '>5. What if i already have my design and i just want you guys to build for me ?</h2>
                    <p className='md:w-1/2 py-4 md:px-12 md:py-20'>Yes, absolutely! If you already have your website design and just need someone to build it for you, I can help with that. I offer website development services that include turning your design into a fully functional website. Whether you have a design in mind or need help creating one, I can work with you to bring your vision to life. Feel free to reach out to me, and let's discuss your website development project in more detail.</p>
                </div>
                <div className=' flex flex-col border-t-2 px-6 border-black md:flex-row '>
                    <h2 className='md:w-1/2 text-left font-bold text-[15px] pt-4 md:px-12 md:py-20 '>6. Can you guys modernize my existing website for me ?</h2>
                    <p className='md:w-1/2 py-4 md:px-12 md:py-20'>Yes, definitely! If you have an existing website that needs a modern touch, I can help. I offer website modernization services to help businesses update their outdated websites and make them more modern and user-friendly. Whether you need to revamp the design, optimize for mobile devices, or improve website functionality, I can work with you to modernize your website and help you achieve your goals. Let me know if you would like to discuss more about my website modernization services.</p>
                </div>
                
                <div className=' flex flex-col border-t-2 px-6 border-black md:flex-row '>
                    <h2 className='md:w-1/2 text-left font-bold text-[15px] pt-4 md:px-12 md:py-20 '>7.How can i trust you guys, how can i be sure that ur legit ?</h2>
                    <p className='md:w-1/2 py-4 md:px-12 md:py-20'>As a website developer, I understand the importance of building trust with my clients. To ensure that you feel confident in my abilities and legitimacy, I encourage you to check out my portfolio of previous work, and you can also connect with me on reputable freelancing platforms like Upwork or LinkedIn. My goal is to build long-term relationships with my clients based on trust, communication, and quality work, so I am committed to delivering on my promises and providing you with the best possible service.</p>
                </div>
                <div className=' flex flex-col border-t-2 px-6 border-black md:flex-row '>
                    <h2 className='md:w-1/2 text-left font-bold text-[15px] pt-4 md:px-12 md:py-20 '>8. Can you guys give me some  adivce on how to approach this marketing thing, like figuring out the right strategy for me ?</h2>
                    <p className='md:w-1/2 py-4 md:px-12 md:py-20'>Yes, definitely! As a website developer with experience in digital and online marketing, I can provide you with advice and guidance on how to approach your marketing strategy. This includes determining your target audience, choosing the right channels for your business, creating engaging content, and measuring your success. I can work with you to develop a customized marketing strategy that meets your specific needs and goals. Let me know if you would like to discuss more about my marketing strategy services.</p>
                </div>
                <div className=' flex flex-col border-t-2 px-6 border-black md:flex-row '>
                    <h2 className='md:w-1/2 text-left font-bold text-[15px] pt-4 md:px-12 md:py-20 '>9. I want to promote my business but i have no idea where to start?</h2>
                    <p className='md:w-1/2 py-4 md:px-12 md:py-20'>No problem! As a web developer with experience in digital and online marketing, I can help you get started with promoting your business. This includes developing a customized marketing strategy, identifying your target audience, choosing the right channels for your business, creating engaging content, and measuring your success. I can work with you to create a plan that meets your specific needs and goals and help you execute it. Let me know if you would like to discuss more about my marketing services and how I can help promote your business.</p>
                </div>
                <div className=' flex flex-col border-t-2 px-6 border-black md:flex-row '>
                    <h2 className='md:w-1/2 text-left font-bold text-[15px] pt-4 md:px-12 md:py-20 '>10. I just want some billboards, how fast can you get them for me?</h2>
                    <p className='md:w-1/2 py-4 md:px-12 md:py-20'>Billboard design and printing, Ican work with you to design and produce billboards for your business. However, the timeline for creating and delivering billboards can vary depending on the complexity of the design, the printing process, and other factors. I would need to discuss your specific requirements and timeline to give you an accurate estimate of how fast we can get the billboards done. Please let me know if you have any further questions or would like to discuss your billboard design and printing needs in more detail.</p>
                </div>
                
               
               

            </div>
        </section>
    </div>
  )
}

export default Faq