import React from 'react'
import LogoE from '../images/logoE.png';
import Bcardi from '../images/bcardi.png';
import Tshirts from '../images/tshirts.png';
import Flyers from '../images/flyers.png';
import Posters from '../images/posters.png';
import Billboards from '../images/billboards.webp';
import { Link, animateScroll as scroll } from "react-scroll";

function Everything() {
  return (
    <div className=' w-full  h-full bg-[#F5F4E7]' id="quick">
        <section className='container mx-auto'>
        <div className=' justify-center items-center md:mx-12' >
            <h2 className='font-bold text-[#36363A] text-5xl md:text-[70px] md:px-[130px] px-6 pt-12 md:pt-20 text-center md:leading-[70px]'>
                Everything you need to sell anything
            </h2>
            <p className=' font-medium font-poppins text-center text-slate-500 text-[20px] md:px-[200px] py-4 px-12 lg:pt-12 md:text-[25px] '>
                Our quick-start package is designed to launch and amplify your brand quickly
            </p>
        </div>
        <div className=' flex w-full h-full px-8 py-4 pt-8 justify-center items-center'>
        <div className='grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-x-12 lg:grid-cols-3 '>
           
        <div className='  w-full h-full flex flex-col text-center items-center justify-center rounded-[25px] bg-white'>
         <div>
         <img className='w-[400px] h-[300px] rounded-t-[25px]' src={LogoE} alt=''/>
         </div>
         <div className=' flex-col text-center  '>
         <h2 className='text-black font-medium text-2xl md:text-[35px] mt-4 pb-4'>Logo-design</h2>
         
        </div>

        </div>
        <div className='  w-full h-full flex flex-col text-center items-center justify-center rounded-[25px] bg-white'>
         <div>
         <img className='w-[400px] h-[300px] rounded-t-[25px]' src={Bcardi} alt=''/>
         </div>
         <div className=' flex-col text-center  '>
         <h2 className='text-black font-medium text-2xl md:text-[35px] mt-4 pb-4'>Business Cards</h2>
         
        </div>

        </div>
        <div className='  w-full h-full flex flex-col text-center items-center justify-center rounded-[25px] bg-white'>
         <div>
         <img className='w-[400px] h-[300px] rounded-t-[25px]' src={Tshirts} alt=''/>
         </div>
         <div className=' flex-col text-center  '>
         <h2 className='text-black font-medium text-2xl md:text-[35px] mt-4 pb-4'> Brand T-shirts</h2>
         
        </div>

        </div>
        <div className='  w-full h-full flex flex-col text-center items-center justify-center rounded-[25px] bg-white'>
         <div>
         <img className='w-[400px] h-[300px] rounded-t-[25px]' src={Flyers} alt=''/>
         </div>
         <div className=' flex-col text-center  '>
         <h2 className='text-black font-medium text-2xl md:text-[35px] mt-4 pb-4'>Flyers</h2>
         
        </div>

        </div>
        <div className='  w-full h-full flex flex-col text-center items-center justify-center rounded-[25px] bg-white'>
         <div>
         <img className='w-[400px] h-[300px] rounded-t-[25px]' src={Posters} alt=''/>
         </div>
         <div className=' flex-col text-center  '>
         <h2 className='text-black font-medium text-2xl md:text-[35px] mt-4 pb-4'>Posters</h2>
        
        </div>

        </div>
        <div className='  w-full h-full flex flex-col text-center items-center justify-center rounded-[25px] bg-white'>
         <div>
         <img className='w-[400px] h-[300px] rounded-t-[25px]' src={Billboards} alt=''/>
         </div>
         <div className=' flex-col text-center  '>
         <h2 className='text-black font-medium text-2xl md:text-[35px] mt-4 pb-4'>Billboards</h2>
        
        </div>

        </div>
            
        
        
        </div>
        </div>
        <div className='flex justify-center items-center py-16 md:p-16'>
        <Link
                activeClass="active"
                to="contacts"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
              <a href='#'className='text-black text-3xl bg-white border-2  border-black px-6 py-2  md:px-12  '>Start Now</a> 
              </Link>
            

        </div>
    </section>

    </div>
  )
}

export default Everything