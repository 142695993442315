import React from 'react';
import Performace from '../images/performace.png';
import Grow from '../images/grow.png';
import Render from '../images/render.png';
import Facebook from '../images/facebook.png';
import Netflix from '../images/netflix.png';
import Instagram from '../images/instagram.png';
import Uber from '../images/uber.png'
import Tiktok from '../images/tiktok.png'
import Airbnb from '../images/airbnb.png'
import Tencent from '../images/tencent.png'
import Coinbase from '../images/coinbase.png'
import Marvel from '../images/marvel.png'
import Hulu from '../images/hulu.png'
import Nodee from "../images/nodee.png"
import Database from "../images/database.png";



function Backbone() {
  return (
    <div className='w-full h-full  bg-white pt-20 pb-20' id="bone">
        <section className='container mx-auto'>
            <div className=' justify-center items-center px-4 py-12 '>
                <h1 className='text-center text-5xl font-bold md:text-7xl'>The Backbone Technology Stack Behind  Our Engineering Prowess</h1>
                <p className='text-center font-medium text-2xl text-slate-500 py-10 px-6 md:px-[300px]'>Transilation- Technologies we use to create world-class websites and mobile app and why we choose them over every thing else.  </p>

            </div>
            <div className=' grid grid-cols-1 md:grid-cols-2 md:gap-10 px-4 items-center justify-center'>
                <div className=' row-span-2 md:border md:shadow-2xl shadow-slate-500 pt-6  md:p-20 srounded-[30px] md:rounded-[50px] mx-'>
                    <h2 className='font-bold text-5xl text-left md:text-left px-4 md:px-12'>React & Next js</h2>
                    <p className='font-light  text-2xl text-left md:text-left px-6 py-4 md:px-12'>I use react and it superset nextjs as our fontend frameworks  for building high-perfomance, scalable, dynamic user interfaces</p>
                    
                    <div className=' py-6 md:flex-none  md:justify-start'>
                        <img  className=' items-left px-6 py-4 md:px-12 ' src={Performace} alt=''/>
                            <h3 className='font-medium text-left text-3xl md:text-left py-4 px-6  md:px-12'>High-performace</h3>
                            <p className='ffont-light text-left text-2xl md:text-left py-2 px-6  md:px-12'>So the number of users won't slow domn the website</p>
                    </div>
                    <div className=' py-6  md:flex-none  md:justify-start'>
                        <img  className='  items-left px-6 py-4 md:px-12 ' src={Grow} alt=''/>
                            <h3 className='font-medium text-left text-3xl md:text-left py-4 px-6  md:px-12'>Scalability</h3>
                            <p className='font-light text-left text-2xl md:text-left py-2 px-6  md:px-12'>So the website keeps up to user spikes</p>
                    </div>
                    <div className=' py-6 md:flex-none  md:justify-start'>
                        <img  className=' items-left px-6 py-4 md:px-12 ' src={Render} alt=''/>
                            <h3 className='font-medium text-left text-3xl md:text-left py-4 px-6  md:px-12'>Constantly Rendering</h3>
                            <p className='font-light text-left text-2xl md:text-left py-2 px-6  md:px-12'>So the newest data is always available</p>
                    </div>
                    <p className='font-light text-left text-2xl md:text-left py-2 px-6  md:px-12'>Originally developed by facedbook react  is the world’s most powerful & most popular fontend-frame work used by world’s biggest companies like Facebook, Netflix and more.
                    Guess what we gonna use the same tech to build your project 🤪 </p>
                    <div className='w-full flex  flex-col justify-center items-center'>
                    <div className='  justify-center items-center grid  grid-cols-1  md:grid-cols-4 md:gap-2 justify-items-center'>
                        <img className=' py-4 ' src={Facebook} alt=''  />
                        <img className=' px-6 py-4   ' src={Netflix} alt=''  />
                        <img className=' px-6 py-4 ' src={Instagram} alt=''  />
                        <img className=' px-6 py-4 ' src={Uber} alt=''  />
                        <img className=' px-6 py-4 ' src={Tiktok} alt=''  />
                        <img className=' px-6 py-4 ' src={Airbnb} alt=''  />
                        <img className=' px-6 py-4 ' src={Tencent} alt=''  />
                        <img className=' px-6 py-4 ' src={Coinbase} alt=''  />
                        <img className=' px-6 py-4 ' src={Marvel} alt=''  />
                        <img className=' px-6 py-4 ' src={Hulu} alt=''  />

                    </div>
                    </div>


                </div>
                {/** grid  backend */}
                <div className=' h-full md:row-span pt-20  md:border md:shadow-2xl shadow-slate-500  md:p-20 srounded-[30px] md:rounded-[50px]'>
                <h2 className='font-bold text-5xl text-left md:text-left px-4 md:px-12'>Backend</h2>
                <img className=' w-1/2 items-left px-6 py-6 md:px-12  ' src={Nodee} alt=''  />
                <p className='font-light text-left text-2xl md:text-left py-2 px-6  md:px-12'>We use nodejs + express.js as our  backend stack for building backend  logic and Api</p>
                <h3 className='font-medium text-left text-3xl md:text-left py-4 px-6  md:px-12'>Sanity CMS</h3>
                <p className='font-light text-left text-2xl md:text-left py-2 px-6  md:px-12'>Sanity cms  as  content management system
capable of storing all kinds of bolb data</p>
                

                </div>
                {/** grid  data store */}
                <div className=' h-full md:row-span- md:border md:shadow-2xl shadow-slate-500 pt-6  md:p-20 srounded-[30px] md:rounded-[50px]'>
                <h2 className='font-bold text-5xl text-left md:text-left px-4 md:px-12 py-10'>Data Store</h2>
                <img  className=' items-left px-6 py-4 md:px-12 ' src={Database} alt=''/>
                <p className='ffont-light text-left text-2xl md:text-left py-2 px-6  md:px-12'>We  choose mongoDb and postgres (via supabase) as 
our main databases , and Google cloud’s firebase ,mongoDB realm 
for mobile first implementation for mobile apps  </p>

                </div>

            </div>

        </section>
    </div>
  )
}

export default Backbone;