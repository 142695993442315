import React from 'react';
import Webuildit from '../images/webuildit.png'
import Designx from '../images/designx.png';
import Fullcirclegirl from '../images/fullcirclegirl.png';
import Mobile from '../images/mobile.png';
import Fashion from '../images/fashion.png';
import Blog from '../images/blog.png';
import Beauty from '../images/beauty.png'
import Travel from '../images/travel.png'
import Ecommerce from '../images/ecommerce.png'
import Edu from '../images/edu.png'






function Dreamit() {
  return (
    <div className='w-full h-full bg-[#F9F2FB] mt-12'>
        <section className='mx-auto container'>
            <div className='text-center justify-center items-center pt-12'>
                <h2 className='text-[#3F3D56] font-extrabold  text-5xl md:text-[50px] mt-4 px-6 md:py-6 '>Dream it and we build it</h2>

            </div>
            <div className=' justify-center items-center pt-8  md:pt-20'>
                <img className='w-full  px-12 rounded-2xl ' src={Webuildit} alt=''/>
                <h2 className='hidden text-white text-center font-bold text-3xl py-4'>Lets build ur dream project</h2>

            </div>
     <div className='px-4  pt-8 md:pt-16'>
        <div className='  w-full h-full flex flex-col text-center items-center justify-center rounded-[25px] bg-[#AAAFB2] shadow-2xl shadow-slate-500 md:flex-row'>
         <div className='w-full md:w-1/2  flex'>
         <img className=' w-full h-[300px] md:h-[500px] rounded-t-[25px] md:rounded-l-[25px] md:rounded-t-none md:rounded-tl-[25px]' src={Designx} alt=''/>
         </div>
         <div className=' flex-col text-center md:w-1/2 md:h-full '>
         <h2 className='text-[#3F3D56] font-bold text-3xl md:text-[50px] mt-4 py-4 px-6 md:py-6 md:leading-[50px]'>UI/UX design</h2>
         <p className='text-black text-[20px] px-8 py-2 pb-8 font-light md:px-[130px]'>Our design process builds the ideal UI and UX for your market. A customized experience for your business and brand that leaves a lasting impression.  
Our entire design process is polished and guaranteed to delight, just like your product.</p>
        </div>

        </div>
        </div>
        <div className='px-4 pt-16'>
        <div className='  w-full h-full flex flex-col text-center items-center justify-center rounded-[25px] bg-[#FBD6DF]  shadow-2xl shadow-slate-500 md:flex-row'>
         <div className=' w-full md:w-1/2  flex'>
         <img className=' w-full h-[300px] md:h-[500px] rounded-t-[25px] md:rounded-l-[25px] md:rounded-t-none md:rounded-tl-[25px]' src={Fullcirclegirl} alt=''/>
         </div>
         <div className=' flex-col text-center md:w-1/2 md:h-full '>
         <h2 className='text-[#3F3D56] font-bold text-3xl md:text-[50px] mt-4 py-4 px-6 md:py-6 md:leading-[50px]'>Websites + webapps</h2>
         <p className='font-poppins text-black text-[20px] px-8 py-2 pb-8 font-light md:px-[130px]'>High converting landing pages to push ur leads to the next level.
         Amazing, blazingly fast page loading and snapy responsiveness that delivers excellent user exprience. </p>
        </div>

        </div>
        </div>
        <h2 className='text-[#3F3D56]  text-center font-extrabold text-4xl md:text-[50px] mt-4  pt-16 py-6 px-6 md:py-6 md:pt-[150px]'>Professionally Designed</h2>
        <p className='text-black  text-center text-[20px] px-8 py-2 pb-8 font-light md:py-[30px] md:px-[130px]'>professionally designed looks that will always delight</p>
         {/* missing grid */} 
    <div className='grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-x-12 lg:grid-cols-3 md:pt-[50px]'>
        <div className='px-4'>
            <img className='px-6 py-4' src={Fashion} alt='' />
            <h3 className='text-center text-black font-medium text-3xl px-6 py-2'>Fashion</h3>
        </div>
        <div className='px-4'>
            <img className='px-6 py-4' src={Blog} alt='' />
            <h3 className='text-center text-black font-medium text-3xl px-6 py-2'>Blog</h3>
        </div>
        <div className='px-4'>
            <img className='px-6 py-4' src={Beauty} alt='' />
            <h3 className='text-center text-black font-medium text-3xl px-6 py-2'>Beauty</h3>
        </div>
        <div className='px-4'>
            <img className='px-6 py-4' src={Travel} alt='' />
            <h3 className='text-center text-black font-medium text-3xl px-6 py-2'>Travel</h3>
        </div>
        <div className='px-4'>
            <img className='px-6 py-4' src={Ecommerce} alt='' />
            <h3 className='text-center text-black font-medium text-3xl px-6 py-2'>Ecommerce</h3>
        </div>
        <div className='px-4'>
            <img className='px-6 py-4' src={Edu} alt='' />
            <h3 className='text-center text-black font-medium text-3xl px-6 py-2'>Education</h3>
        </div>

    </div>

     <div className='px-4 pt-16 pb-16'>
        <div className='  w-full h-full flex flex-col text-center items-center justify-center rounded-[25px] bg-[#ffffff] shadow-2xl shadow-slate-700 md:flex-row'>
         <div className=' w-full md:w-1/2  flex'>
         <img className=' w-full h-[300px] md:h-[500px] rounded-t-[25px] md:rounded-l-[25px] md:rounded-t-none md:rounded-tl-[25px]' src={Mobile} alt=''/>
         </div>
         <div className=' flex-col text-center md:w-1/2 md:h-full '>
         <h2 className='text-[#40A067] font-bold text-3xl md:text-[70px] mt-4 py-4 px-6 md:py-6 md:leading-[50px]'>ios & Andoid 
apps</h2>
         <p className='text-black text-[20px] px-8 py-2 pb-8 font-light md:px-[130px]'>We use React Native as well as fully native development to optimize your project and share logic across platforms. Translation: you pay to build your app once, and it works on both iOS and Android.</p>
        </div>

        </div>
        </div>

        </section>
        
    </div>
  )
}

export default Dreamit;